import jQuery from "jquery";
import * as Bowser from "bowser";

export let CookieManager = {

    getCookie: function (cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    createCookie: function (name, value, days) {
        var date = new Date(),
            expires = '';
        if (days) {
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    },

    createCookielawCookie: function () {
        this.createCookie('cookielaw_accepted', '1', 10 * 365);

        if (typeof (window.jQuery) === 'function') {
            jQuery('#CookielawBanner').slideUp();
        } else {
            document.getElementById('CookielawBanner').style.display = 'none';
        }
    },

    createBrowserWarningCookie: function () {
        this.createCookie('browser_warning', '1', 0);

        document.getElementById('browserWarningBanner').style.display = 'none';
    },

    initBrowserWarning: function () {
        var browser;
        var isUnsupported = true;
        if (! this.getCookie('browser_warning')) {
            if (typeof Bowser !== 'undefined') {
                browser = Bowser.getParser(window.navigator.userAgent);
                isUnsupported = browser.satisfies(
                    {"internet explorer": "<=10",
                     "chrome": "<49",
                     "firefox": "<52", // la version 52.9 e ven mostrade come 52.0
                     "safari": "<9.1"
                    });
            } // se undefined il browser al è cussì vieri che nol supuarte la librerie e alore nol è supuartât

            if (isUnsupported) {
                var message = document.getElementById("browserWarningBanner");
                message.style.display = "block";
            }
        }
    }

};
